@media screen {
  @import 'settings';

  body {
    display: grid;

    grid-template-areas:
    '.      '
    'header '
    '.      '
    'main   '
    '.      '
    'footer ';

    grid-template-columns: 1fr;
    grid-template-rows: $row-spacing auto $row-spacing auto $row-spacing auto;

    margin: 0 $column-spacing;

    @media (min-width: $medium-min-width) {
      // vertical | horizontal
      margin: 0 $column-spacing * 2;
    }

    @media (min-width: $large-min-width) {
      // vertical | horizontal
      margin: 0 $column-spacing * 5;
    }
  }

  header[role='banner'] {
    grid-area: header;
  }

  main {
    grid-area: main;
  }

  footer[role='contentinfo'] {
    align-self: center;
    grid-area: footer;
    margin-bottom: $row-spacing / 2;
  }
}
