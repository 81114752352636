@import 'settings';
@import 'rouge';

$highlight-padding: 1.25em;

div {
  &.highlight {
    margin: 0 auto 1em;
    width: 92%;
  }
}

pre {
  &.highlight {
    background-color: $highlight-background-color;
    border: 1px solid $border-color;
    border-radius: $rounded-radius;

    line-height: 110%;
    margin: 0;
    overflow: auto;
    padding: $highlight-padding;

    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
  }
}

:not(pre) > code {
  background-color: $highlight-background-color;
  padding: .2em;
}

code {
  font-family: $font-family-code;
  font-size: .9em;
}

