// csslint ignore:start
// sass-lint:disable no-ids
#disqus_thread {
  margin-top: 45px;
}

.fa-comments-o {
  // https://github.com/ForkAwesome/Fork-Awesome/blob/master/src/icons/svg/comments-o.svg
  background-image: url('data:image/svg+xml, \
      <svg width="1em" height="1em" viewBox="0 100 1536 1536" xmlns="http://www.w3.org/2000/svg" version="1.1"> \
          <path d="M704 384c-312 0-576 176-576 384 0 110 74 216 202 290l97 56-35 84c21-12 42-25 62-39l44-31 53 10c50 9 101 14 153 14 312 0 576-176 576-384s-264-384-576-384zm0-128c389 0 704 229 704 512s-315 512-704 512c-61 0-120-6-176-16-83 59-177 102-278 128-27 7-56 12-86 16h-3c-15 0-29-12-32-29-4-19 9-31 20-44 39-44 83-83 117-166C104 1075 0 930 0 768c0-283 315-512 704-512zm822 1169c34 83 78 122 117 166 11 13 24 25 20 44-4 18-19 31-35 29-30-4-59-9-86-16-101-26-195-69-278-128-56 10-115 16-176 16-181 0-347-50-472-132 29 2 59 4 88 4 215 0 418-62 573-174 167-122 259-287 259-466 0-52-8-103-23-152 169 93 279 241 279 408 0 163-104 307-266 401z"/> \
      </svg>');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  padding-left: 1.2em;
}

#dsq-comments {
  &.dsq-comment-footer {
    margin-top: 5px;
  }
}
// csslint ignore:end
