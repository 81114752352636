@import 'settings';

.categories {
  ul {
    display: block;
    padding: 0;

    li {
      display: inline-block;
      margin: .4em .2em;
    }
  }
}

.category {
  background-color: transparentize($category-color, .3);
  border-radius: .5em;
  color: $body-bg;
  padding: .3em .4em;

  &:hover {
    background-color: $category-color;
    color: darken($body-bg, 10%);
    text-decoration: none;
    transition: background-color .1s ease-in-out;
  }
}
