@import 'settings';

header.resume {
  @media print {
    display: none;
  }
}

main.resume {
  $header-color: map-get($p, 800);
  $nav-color: $primary-color-dark;

  @media screen {
    display: grid;

    grid-template-areas:
    'sidebar'
    'sections';
    grid-template-columns: 1fr;
  }

  // navbar on the left
  @media screen and (min-width: $medium-min-width) {
    // offset-x  offset-y blur-radius color;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);

    grid-template-areas: 'sidebar . sections';
    grid-template-columns: .25fr 1em .75fr;
  }

  nav {
    background-color: $nav-color;
    border-radius: .5em 0 0;
    color: $body-bg;
    grid-area: sidebar;

    a {
      color: inherit;
    }

    header {
      background-color: $header-color;
      border-radius: inherit;
      padding-left: 1em;

      h1 {
        margin: 0;

        @media print {
          font-size: 2em;
        }
      }

      img {
        clip-path: circle(50%);
        display: none;
        width: 100%;
      }

      @media (min-width: $medium-min-width) {
        padding: 1em 1.5em;
        text-align: center;

        img {
          display: block;
        }
      }
    }

    ul {
      @include hyphenate-character('');
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      list-style: none;
      margin-bottom: 0;
      padding: 1em 0 0 1em;

      li {
        margin-bottom: .5em;
        margin-right: 1em;
      }

      @media screen and (min-width: $medium-min-width) {
        display: block;
      }

      .download-pdf {
        @media print {
          display: none;
        }
      }
    }
  }

  .subjects {
    padding-top: .5em;
  }

  .skills {
    li {
      padding: .2em;
    }
  }

  .sections {
    grid-area: sections;
    padding-right: 1em;

    @media print {
      border-top: 1px solid;

      section.pos3 {
        page-break-before: always;
      }
    }
  }

  section {
    margin-top: 1em;

    & + section {
      margin-top: 1.5em;
    }
  }

  .place-period {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    @media print, (min-width: $medium-min-width) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .period {
    color: $text-muted;
  }

  .level {
    font-size: .8em;
    font-variant: small-caps;
  }

  abbr {
    @media screen {
      border-bottom: 1px dashed $body-color;
    }
    text-decoration: none;
  }

  p {
    margin-bottom: .5em;
  }
}
