@import 'settings';
@import 'mixins';

.post {
  display: grid;

  grid-template-areas:
  'intro   intro   intro  '
  'content content content'
  'outro   outro   outro  ';

  grid-template-columns: .8fr $column-spacing .2fr;
  grid-template-rows: auto auto auto;

  @media (min-width: $large-min-width) {
    grid-template-areas:
    'intro   intro  intro  '
    'content .      sidebar'
    'outro   outro  outro  ';
  }

  header {
    grid-area: intro;
  }

  section {
    grid-area: content;
  }

  footer {
    grid-area: outro;
    margin-top: $row-spacing;
  }

  aside {
    display: none;
    grid-area: sidebar;

    @media (min-width: $large-min-width) {
      display: block;
    }
  }

  img {
    border: 1px solid $border-color;
    border-radius: $rounded-radius;

    margin-bottom: .1em;
    max-width: $max-image-size;
    padding: .4em;
  }

  figure {
    border: 1px solid $border-color;
    border-radius: $rounded-radius;
    display: table;
    max-width: $max-image-size;
    padding-bottom: 1em;

    img {
      border: 0;
      max-width: 100%;
    }

    figcaption {
      caption-side: bottom;
      display: table-caption;
      font-size: smaller;
      padding: .5em 1em;
      text-align: center;
    }
  }

  .left {
    float: left;
    margin-left: $img-side-margin / 2;
    margin-right: $img-side-margin;
  }

  .right {
    float: right;
    margin-left: $img-side-margin;
    margin-right: $img-side-margin / 2;
  }

  .full {
    max-width: 100% !important; // sass-lint:disable-line no-important
  }

  .published-date-main {
    @media (min-width: $large-min-width) {
      display: none;
    }
  }
}

.sidebar {
  @include sticky;

  border-left: .1em dotted $border-color;
  padding-left: 1em;
  top: 3em;

  div {
    margin-bottom: 1em;
  }
}

footer {
  .previous-and-next {
    border-bottom: solid 1px $border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    padding-top: .5em;
  }
}
