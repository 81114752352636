@import 'settings';
@import 'tippy';

$transition-time: .3s;
$small-size:  .8em;
$flex-margin: 1em;
$border-color: #808080;
$shadow-color: #000;

header.projects {
  align-items: start;
  display: grid;
  grid-template-areas:
  'title'
  'icons';

  grid-template-columns: auto;
  grid-template-rows: auto auto;

  .social-media-icons {
    display: none;

    @media (min-width: $small-min-width) {
      background: $primary-color-dark;
      border: solid 1px $border-color;
      border-radius: $rounded-radius;

      display: grid;
      font-size: 2em;
      grid-area: icons;
      grid-auto-columns: auto;
      grid-auto-flow: column;  // create implicit columns
      grid-template-rows: 1fr; // 1 explicit row
      justify-content: space-evenly; // requires "grid-auto-columns: auto"

      padding: .2em 0;

      svg {
        @include prop(--background-color, $primary-color-dark);
        @include prop(--foreground-color, $body-bg);
        color: $body-bg;
        display: block;
        filter: none;
      }
    }
  }
}

nav.projects {
  align-content: space-between;
  align-items: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-evenly;
  margin-bottom: -$flex-margin;
  margin-top: -$flex-margin;
}

.project {
  border: solid 1px $border-color;
  border-radius: $rounded-radius;
  display: block;

  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;

  margin-bottom: $flex-margin * 2; // should be handled by flex (row-gap, column-gap)
  padding: $flex-margin;

  transition: all $transition-time ease; // sass-lint:disable-line no-transition-all

  > header {
    display: grid;
    grid-template-areas:
    'title  title  title'
    'sub    sub    sub  '
    'sub2   sub2   sub2 ';

    @media (min-width: $medium-min-width) {
      grid-template-areas:
      'title title  title'
      'sub   sub    sub2';
    }

    grid-template-columns: max-content 1fr max-content;
    grid-template-rows:    auto auto auto;

    transition: inherit;

    h2 {
      cursor: pointer;
      grid-area: title;
      margin-bottom: 0;
      transition: inherit;
    }

    .sub {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      grid-area: sub;
      transition: inherit;

      span {
        margin-right: 1em;
      }
    }

    .logo {
      height: 1.1em;
      vertical-align: text-top;
      width: auto;
    }
  }

  &[open] {
    // offset-x | offset-y | blur-radius | spread-radius | color
    box-shadow: 1px 1px 1px 1px $shadow-color;
    margin-right: 0;

    h3 {
      text-shadow: 0 0 1px rgba(0, 0, 0, .2);
    }

    .technologies,
    .platforms {
      svg {
        filter: none;
      }
    }

    .stats { // show stats in expanded state
      display: inline-flex;
    }

    @media (min-width: $medium-min-width) {
      h2 {
        font-size: 2em;
      }

      .sub,
      .sub2 {
        font-size: 1.2em;
      }
    }
  }

  summary {
    color: $primary-color; // emulate -webkit-details-marker for other browsers
    cursor: pointer;
    margin-top: .5em;

    & + * {
      margin-top: 1em;
    }

    &::-webkit-details-marker { // sass-lint:disable-line no-vendor-prefixes
      color: $primary-color;
    }

    h3 {
      color: $body-color;
      display: inline;
      font-weight: normal;
    }
  }

  details {
    @include icon-external-link(--icon-external-link, rgba($primary-color, .9));

    p > a[href*='//'] {
      &::after {
        $size: .7em;

        background-image: var(--icon-external-link);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: $size $size;

        content: '\00a0';
        display: inline-block;

        height: $size;
        margin-left: .1em;
        vertical-align: -.4em;
        width: $size;
      }
    }
  }

  footer { // store badges
    @include column-gap(2em);
    @include row-gap(1em);
    align-items: center;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fit, minmax(135px, 170px));

    justify-content: center;

    margin: auto 2em;

    img {
      width: 100%;
    }
  }

  .period,
  .stats {
    color: $text-muted;
    font-size: $small-size;
  }

  .stats {
    display: none;

    span[class$='-count'] {
      margin-left: .1em;
    }

    a {
      color: inherit;

      &+a {
        margin-left: .5em;
      }

      &:hover {
        color: darken($text-muted, 10);
      }
    }
  }

  .screenshots,
  .presentations {
    list-style: none;
    padding: 0;

    @media (min-width: $small-min-width) {
      @include column-gap(2em);
      @include row-gap(1em);

      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: auto;
      grid-template-columns: repeat(auto-fit, minmax(200px, .4fr));
      justify-content: space-evenly;
    }
  }

  .presentations {
    @media (min-width: $small-min-width) {
      float: left;
      margin-right: 1em;
    }
  }

  .screenshot,
  .presentation {
    figure {
      display: table;
      margin: 0;

      img {
        border: solid 1px $border-color;
        border-radius: .1em;
        max-width: 100%;
      }

      > figcaption {
        caption-side: bottom;
        font-size: $small-size;
        padding: .5em 1em;
        text-align: center;
      }
    }
  }

  .sub2 {
    @include column-gap(1em);
    align-items: baseline;
    display: inline-grid;
    grid-area: sub2;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
  }

  .technologies,
  .platforms {
    @include column-gap(.4em);
    align-items: baseline;
    color: $text-muted;
    display: inline-grid;
    grid-auto-flow: column;

    transition: inherit;

    span {
      &:hover {
        color: $primary-color;
      }
    }
  }
} // project

// enable hyphenation on small screens
@media (max-width: $medium-min-width) {
  .description,
  .more-details {
    @include hyphenation;
  }
}

svg {
  &.clock {
    vertical-align: -.125em; // relative to baseline, override .icon
  }

  &.pencil {
    vertical-align: -.2em;  // relative to baseline, override .icon
  }

  &.pdf {
    filter: none;
  }
}
