@import 'settings';
$tippy-color: $primary-color-dark;

// https://atomiks.github.io/tippyjs/themes/
.tippy-backdrop {
  background: $tippy-color;
}

.tippy-tooltip {
  background: $tippy-color;
}

@mixin arrow($placement) {
  &[x-placement^='#{$placement}'] {
    .tippy-arrow {
      border-#{$placement}-color: $tippy-color;
    }
  }
}

.tippy-popper {
  @include arrow(top);
  @include arrow(bottom);
  @include arrow(left);
  @include arrow(right);
}

.tippy-roundarrow {
  fill: $tippy-color;
}
