svg {
  &.icon {
    // apply hover only on non-touchscreen devices
    @media (pointer: fine) {
      filter: grayscale(100%);
      transition: filter .2s ease;

      &:hover {
        filter: none;
      }
    }

    height: 1em;
    vertical-align: text-top;
  }

  // SVG symbols for later reference via <use/>
  // override HTML defaults of 300x150 for replaced inline elements
  &.symbols {
    height: 0;
    width: 0;
  }

  &.cc,
  &.github {
    vertical-align: -.125em;
  }

  &.floppy,
  &.envelope,
  &.gitlab,
  &.web {
    vertical-align: -.2em;
  }
}
