// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
$p: (
  50: #e2f4fa,
  100: #b5e4f3,
  200: #86d2ec,
  300: #5ec0e4,
  400: #44b2e0,
  500: #33a5db,
  600: #2d98cd,
  700: #2585ba,
  800: #2274a6,
  900: #195584
);

$primary-color: map-get($p, 600) !default;
$primary-color-dark: map-get($p, 900);

$category-color: $primary-color;
$quote-color: transparentize($primary-color, .97);
$border-color: #ccc;

$small-min-width:  384px;  // Small devices
$medium-min-width: 768px;  // Medium devices (tablets,  768px and up)
$large-min-width:  992px;  // Large devices  (desktops, 992px and up)

$img-side-margin: 1.3em;
$max-image-size: 40%;
$row-spacing: 1.5em;
$column-spacing: $row-spacing;
$rounded-radius: .3em;

// copied mostly verbatim from bootstrap
$gray-600: #868e96 !default;
$gray-900: #212529 !default;

$body-bg:       #fafafa !default;
$body-color:    $gray-900 !default;

$font-family-sans-serif: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-family-base:       $font-family-sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-code:       'SF Mono', 'Source Code Pro', $font-family-monospace;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-normal: normal !default;
$font-weight-base: $font-weight-normal !default;
$font-weight-bold: bold !default;

$line-height-base: 1.5 !default;

$paragraph-margin-bottom: 1rem !default;
$dt-font-weight: $font-weight-bold !default;

$link-color:            $primary-color !default; // was theme-color("primary")
$link-decoration:       none !default;
$link-hover-color:      $link-color;
$link-hover-decoration: underline !default;

$enable-hover-media-query:  false !default;

$table-cell-padding:            .75rem !default;
$text-muted: $gray-600 !default;
