// sass-lint:disable no-duplicate-properties, no-vendor-prefixes
@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
  // scss-lint:disable Indentation
  &:hover { @content; }
  // scss-lint:enable Indentation
  //  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }
    @include hover { @content; }
  } @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content;
    }
    @include hover { @content; }
  } @else {
    &,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content;
    }
    @include hover { @content; }
  } @else {
    &:focus,
    &:active,
    &:hover {
      @content;
    }
  }
}

// CSS Intrinsic & Extrinsic Sizing Module Level 3
// https://drafts.csswg.org/css-sizing-3/#indefinite
@mixin width-min-content {
  width: min-content;
  width: -moz-min-content;
  width: -webkit-min-content;
}

@mixin width-max-content {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

@mixin sticky {
  position: -webkit-sticky;
  position: sticky;
}

@mixin hyphenation {
  -webkit-hyphens: auto;
  hyphens: auto;
}

// https://drafts.csswg.org/css-text-4/#hyphenate-character
@mixin hyphenate-character($value) {
  -webkit-hyphenate-character: $value;
}

// CSS Grid Layout initially defined the grid-row-gap property.
// This prefixed property is being replaced by row-gap.
@mixin row-gap($value) {
  grid-row-gap: $value;
  row-gap: $value; // sass-lint:disable-line no-misspelled-properties
}

// CSS Grid Layout initially defined the grid-column-gap property.
// This prefixed property is being replaced by column-gap.
@mixin column-gap($value) {
  // sass-lint:disable-block property-sort-order
  grid-column-gap: $value;
  column-gap: $value;
}

// https://github.com/sasstools/sass-lint/issues/1161#issuecomment-390537190
@mixin prop($prop, $value) {
  #{$prop}: $value;
}

// https://github.com/ForkAwesome/Fork-Awesome/blob/master/src/icons/svg/external-link-square.svg
@mixin icon-external-link($prop, $color) {
  #{$prop}: url('data:image/svg+xml, \
    <svg width="1536px" height="1536px" viewBox="0 0 1536 1536" xmlns="http://www.w3.org/2000/svg" version="1.1"> \
        <path fill="#{$color}" d="M1280,800 L1280,320 C1280,285 1251,256 1216,256 L736,256 C710,256 687,272 677,295 C667,319 672,347 691,365 L835,509 L301,1043 C276,1068 276,1108 301,1133 L403,1235 C428,1260 468,1260 493,1235 L1027,701 L1171,845 C1183,858 1199,864 1216,864 C1224,864 1233,862 1241,859 C1264,849 1280,826 1280,800 Z M1536,288 L1536,1248 C1536,1407 1407,1536 1248,1536 L288,1536 C129,1536 0,1407 0,1248 L0,288 C0,129 129,0 288,0 L1248,0 C1407,0 1536,129 1536,288 Z"/> \
    </svg>');
}

@mixin list-style-image-resume {
  list-style-image: url('data:image/svg+xml, \
    <svg width="1em" height="1em" viewBox="0 100 1536 1536" xmlns="http://www.w3.org/2000/svg" version="1.1"> \
        <path fill="currentColor" d="M384 1408c0 106-86 192-192 192S0 1514 0 1408s86-192 192-192 192 86 192 192zm0-512c0 106-86 192-192 192S0 1002 0 896s86-192 192-192 192 86 192 192zm1408 416v192c0 17-15 32-32 32H544c-17 0-32-15-32-32v-192c0-17 15-32 32-32h1216c17 0 32 15 32 32zM384 384c0 106-86 192-192 192S0 490 0 384s86-192 192-192 192 86 192 192zm1408 416v192c0 17-15 32-32 32H544c-17 0-32-15-32-32V800c0-17 15-32 32-32h1216c17 0 32 15 32 32zm0-512v192c0 17-15 32-32 32H544c-17 0-32-15-32-32V288c0-17 15-32 32-32h1216c17 0 32 15 32 32z"/> \
    </svg>');
}

@mixin list-style-image-floppy {
  list-style-image: url('data:image/svg+xml, \
    <svg width="1em" height="1em" viewBox="0 0 1536 1536" xmlns="http://www.w3.org/2000/svg" version="1.1"> \
        <path fill="currentColor" d="M384,1408 L1152,1408 L1152,1024 L384,1024 L384,1408 Z M1280,1408 L1408,1408 L1408,512 C1408,493 1391,452 1378,439 L1097,158 C1083,144 1044,128 1024,128 L1024,544 C1024,597 981,640 928,640 L352,640 C299,640 256,597 256,544 L256,128 L128,128 L128,1408 L256,1408 L256,992 C256,939 299,896 352,896 L1184,896 C1237,896 1280,939 1280,992 L1280,1408 Z M896,480 L896,160 C896,143 881,128 864,128 L672,128 C655,128 640,143 640,160 L640,480 C640,497 655,512 672,512 L864,512 C881,512 896,497 896,480 Z M1536,512 L1536,1440 C1536,1493 1493,1536 1440,1536 L96,1536 C43,1536 0,1493 0,1440 L0,96 C0,43 43,0 96,0 L1024,0 C1077,0 1150,30 1188,68 L1468,348 C1506,386 1536,459 1536,512 Z"/> \
    </svg>');
}

@mixin list-style-image-blog {
  list-style-image: url('data:image/svg+xml, \
    <svg width="1em" height="1em" viewBox="0 0 1536 1536" xmlns="http://www.w3.org/2000/svg" version="1.1"> \
        <path fill="currentColor" d="M384 1344c0 106-86 192-192 192S0 1450 0 1344s86-192 192-192 192 86 192 192zm512 123c1 18-5 35-17 48-12 14-29 21-47 21H697c-33 0-60-25-63-58-29-305-271-547-576-576-33-3-58-30-58-63V704c0-18 7-35 21-47 11-11 27-17 43-17h5c213 17 414 110 565 262 152 151 245 352 262 565zm512 2c1 17-5 34-18 47-12 13-28 20-46 20h-143c-34 0-62-26-64-60C1104 895 641 432 60 398c-34-2-60-30-60-63V192c0-18 7-34 20-46 12-12 28-18 44-18h3c350 18 679 165 927 414 249 248 396 577 414 927z"/> \
    </svg>');
}
