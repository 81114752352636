@import 'settings';
@import 'disqus';
@import 'highlighting';
@import 'post';
@import 'grid';
@import 'fonts';
@import 'quote';
@import 'svg';
@import 'categories';
@import 'mixins';
@import 'projects';
@import 'resume';

main.home {
  ol {
    font-size: 1.2em;

    li {
      margin-bottom: .5rem;
      // sass-lint:disable-block nesting-depth
      &.resume { @include list-style-image-resume; }
      &.projects { @include list-style-image-floppy; }
      &.blog { @include list-style-image-blog; }
    }

    .description {
      font-size: 60%;
    }
  }
}

// 404.html
.error {
  border: solid 1px $border-color;
  font-size: 1.5em;
  padding: 1em 0;
  text-align: center;
}

// disable wrapping inside links for resume/projects
main.resume a,
main.projects a {
  white-space: nowrap;
}

a {
  padding: 2px 1px 0;
}

header[role='banner'] {
  h1 {
    font-size: 2.5em;
    margin: 0;
    text-align: center;

    @media (min-width: $medium-min-width) {
      font-size: 3em;
      letter-spacing: .1em;
    }
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

body > header > h1,
.post > header > h1 {
  font-family: 'Crimson Text', serif;
  font-weight: 700;
  margin-bottom: $paragraph-margin-bottom * .66;
}

h3,
h4,
h5,
h6 {
  margin-bottom: $paragraph-margin-bottom * .66;
}

.posts {
  list-style: none;
  margin-bottom: 1.75rem;
  padding-left: 2em;

  li {
    margin-bottom: 1rem;

    @media (min-width: $medium-min-width) {
      margin-bottom: .3rem;
    }
  }

  .year {
    letter-spacing: .1em;
    margin-bottom: 0;
  }

  .date {
    color: $text-muted;
  }

  .entry-published-date {
    @media (min-width: $medium-min-width) {
      float: right;
    }
  }

  .author {
    display: none;
  }
}

footer[role='contentinfo'] {
  display: flex;
  justify-content: space-between;

  .copyright {
    color: $text-muted;

    &:hover {
      color: darken($text-muted, 10);
    }
  }

  .social-media-icons {
    @include column-gap(.4em);
    display: inline-grid;
    grid-auto-flow: column;
  }

  @media print {
    display: none;
  }
}

@media print {
  * {
    // sass-lint:disable-block no-important
    background: transparent !important;
    color: #000 !important;
  }

  h2,
  h3 {
    break-after: avoid;
  }

  body {
    margin: 0;
  }

  // currently only works in Chrome/Firefox
  @page {
    margin: 1.5cm;
  }
}
