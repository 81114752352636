@import 'settings';

$quote-padding: 1.25em;
$quote-border-width: .1em;

blockquote {
  background-color: $quote-color;
  border-left: $quote-border-width solid darken($quote-color, 30);
  border-radius: $rounded-radius;
  border-right: $quote-border-width solid darken($quote-color, 30);
  margin-bottom: $quote-padding;
  overflow: hidden;
  padding: $quote-padding $quote-padding 0;

  &::before {
    color: lighten($text-muted, 10);
    content: '“';
    display: block;
    font-size: 4em;
    height: 1px;
    left: -.3em;
    position: relative;
    top: -$quote-padding / 2.75;
  }

  cite {
    float: right;
    padding-bottom: $quote-padding / 2;
  }
}
